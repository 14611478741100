import React, { useState, useRef, useEffect } from "react";
import { Select, Button, Input, Table, Typography, message, Tabs } from "antd";

import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  StopOutlined,
  DownloadOutlined,
  AudioOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
const { Paragraph } = Typography;
const { TabPane } = Tabs;

const API_TOKEN = "OpenAI2024";
const formatTime = (time) => {
  const minutes = String(Math.floor(time / 60)).padStart(2, "0");
  const seconds = String(time % 60).padStart(2, "0");
  return `${minutes}:${seconds}`;
};

function App() {
  const [text, setText] = useState("");
  const [voice, setVoice] = useState("shimmer");
  const [format, setFormat] = useState("mp3");
  const [audioFiles, setAudioFiles] = useState([]);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(null);
  const [expandedTextIndex, setExpandedTextIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [transcriptionsText, setTranscriptionsText] = useState("");
  const [recording, setRecording] = useState(false);

  const [recordingTime, setRecordingTime] = useState(0);
  const [timer, setTimer] = useState(null);

  const mediaRecorderRef = useRef(null);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    // Cleanup audio event listeners on component unmount or when switching audio
    return () => {
      if (currentAudio) {
        currentAudio.removeEventListener("ended", onAudioEnd);
        currentAudio.removeEventListener("play", onAudioPlay);
        currentAudio.removeEventListener("pause", onAudioPause);
      }
    };
  }, [currentAudio]);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleVoiceChange = (value) => {
    setVoice(value);
  };

  const handleFormatChange = (value) => {
    setFormat(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    try {
      const response = await fetch(
        "https://oaitts.linuxdo.live/v1/audio/speech",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model: "tts-1",
            input: text,
            voice: voice,
            response_format: format,
          }),
          signal,
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setAudioFiles([...audioFiles, { text, url, format, voice }]);
        setText(""); // 清空文本框
        message.success("音频生成成功！");
      } else {
        message.error("音频生成失败，请稍后再试。");
      }
    } catch (error) {
      if (error.name === "AbortError") {
        message.info("请求已取消");
      } else {
        message.error("网络错误，请检查你的连接。");
      }
    } finally {
      setLoading(false);
      abortControllerRef.current = null;
    }
  };

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  const onAudioEnd = () => {
    setCurrentAudioIndex(null);
  };

  const onAudioPlay = () => {
    setPlaying(true);
  };

  const onAudioPause = () => {
    setPlaying(false);
  };

  const handleAudioPlayPause = (index, url) => {
    if (currentAudioIndex === index) {
      if (currentAudio.paused) {
        setPlaying(true);
        currentAudio.play();
      } else {
        setPlaying(false);
        currentAudio.pause();
      }
    } else {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      const audio = new Audio(url);
      setCurrentAudio(audio);
      setCurrentAudioIndex(index);

      // Attach event listeners
      audio.addEventListener("ended", onAudioEnd);
      audio.addEventListener("play", onAudioPlay);
      audio.addEventListener("pause", onAudioPause);

      audio.play();
    }
  };

  const handleAudioStop = (record, index) => {
    if (currentAudio && currentAudioIndex === index) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudioIndex(null);
      setPlaying(false);
    }
  };

  const handleDownload = (record, index) => {
    const a = document.createElement("a");
    a.href = record.url;
    a.download = `audio_${index + 1}_${record.voice}.${format}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const toggleTextExpansion = (index) => {
    setExpandedTextIndex(expandedTextIndex === index ? null : index);
  };

  const startStopRecording = () => {
    if (recording) {
      // Stop recording logic
      clearInterval(timer);
      setTimer(null);
      stopRecording();
    } else {
      // Start recording logic
      const newTimer = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
      setTimer(newTimer);
      startRecording();
    }
  };

  const startRecording = () => {
    const getUserMedia = navigator.mediaDevices?.getUserMedia ?? null;
    if (!getUserMedia) {
      message.error("浏览器不支持录音功能，请使用最新版本的浏览器。");
      return;
    }

    setRecording(true);

    navigator.mediaDevices
      ?.getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        const chunks = [];

        mediaRecorder.ondataavailable = (event) => {
          chunks.push(event.data);
        };

        mediaRecorder.onstop = async () => {
          const blob = new Blob(chunks, { type: "audio/mpeg" });

          // 从 Blob 中读取数据
          // const arrayBuffer = await blob.arrayBuffer();

          const formData = new FormData();
          formData.append("file", blob, `recording.mp3`);
          formData.append("model", "whisper-1");

          try {
            const response = await fetch(
              "https://oaitts.linuxdo.live/v1/audio/transcriptions",
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${API_TOKEN}`,
                },
                body: formData,
              }
            );

            if (response.ok) {
              const result = await response.json();
              if (result.text) {
                setTranscriptionsText(
                  transcriptionsText +
                    (transcriptionsText ? "\n\n" : "") +
                    result.text
                );
                message.success("录音转文字成功！");
              } else {
                message.info("empty response");
              }
            } else {
              message.error("录音转文字失败，请稍后再试。");
            }
          } catch (error) {
            message.error(error);
            message.error("网络错误，请检查你的连接。");
          }
        };

        mediaRecorder.start();
      })
      .catch((error) => {
        console.error(error);
        console.error("getUserMedia error:", error);
        if (
          error.name === "NotAllowedError" ||
          error.name === "PermissionDeniedError"
        ) {
          message.error("麦克风访问被拒绝，请检查权限设置。");
        } else {
          message.error("无法访问麦克风，请检查设备设置。");
        }
        setRecording(false);
      });
  };

  const stopRecording = () => {
    setRecording(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  useEffect(() => {
    if (!recording) {
      setRecordingTime(0);
    }
  }, [recording]);

  const columns = [
    {
      title: "序号",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "文本内容",
      dataIndex: "text",
      key: "text",
      render: (text, record, index) => (
        <div>
          {text.length > 150 && (
            <Button onClick={() => toggleTextExpansion(index)}>
              {expandedTextIndex === index ? "Collapse" : "Expand"}
            </Button>
          )}
          <Paragraph
            ellipsis={{
              rows: expandedTextIndex === index ? 0 : 3,
              expandable: false,
            }}
          >
            {text}
          </Paragraph>
        </div>
      ),
    },
    {
      title: "操作",
      key: "action",
      render: (text, record, index) => (
        <div>
          <Button
            icon={
              currentAudioIndex === index && playing ? (
                <PauseCircleOutlined />
              ) : (
                <PlayCircleOutlined />
              )
            }
            onClick={() => handleAudioPlayPause(index, record.url)}
            style={{ marginRight: "10px" }}
          />
          <Button
            icon={<StopOutlined />}
            onClick={() => handleAudioStop(record, index)}
            style={{ marginRight: "10px" }}
          />
          <Button
            icon={<DownloadOutlined />}
            onClick={() => handleDownload(record, index)}
          />
        </div>
      ),
    },
  ];

  const tabItems = [
    {
      key: "1",
      label: "TTS",
      children: (
        <>
          <TextArea
            rows={4}
            value={text}
            onChange={handleTextChange}
            placeholder="Enter text"
          />
          <Select
            defaultValue="shimmer"
            style={{ width: 120, margin: "10px" }}
            onChange={handleVoiceChange}
          >
            <Option value="alloy">Alloy</Option>
            <Option value="echo">Echo</Option>
            <Option value="fable">Fable</Option>
            <Option value="onyx">Onyx</Option>
            <Option value="nova">Nova</Option>
            <Option value="shimmer">Shimmer</Option>
          </Select>
          <Select
            defaultValue="mp3"
            style={{ width: 120, margin: "10px" }}
            onChange={handleFormatChange}
          >
            <Option value="mp3">MP3</Option>
            <Option value="aac">AAC</Option>
            <Option value="flac" disabled>
              FLAC
            </Option>
            <Option value="wav" disabled>
              WAV
            </Option>
            <Option value="pcm" disabled>
              PCM
            </Option>
          </Select>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            style={{ marginRight: "10px" }}
          >
            Generate Audio
          </Button>
          {loading && <Button onClick={handleCancel}>Cancel</Button>}
          <Table
            style={{ marginTop: "20px" }}
            dataSource={audioFiles.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            pagination={false}
          />
        </>
      ),
    },
    {
      key: "2",
      label: "Transcriptions",
      children: <><TextArea
      rows={20}
      value={transcriptionsText}
      readOnly
      placeholder="Transcriptions will appear here"
    />
    <Button
      type="primary"
      shape="circle"
      icon={recording ? <StopOutlined /> : <AudioOutlined />}
      size="large"
      onClick={startStopRecording}
      style={{ position: "fixed", bottom: "20px", right: "20px" }}
    />
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "70px",
        padding: "4px",
        borderRadius: 3,
        fontSize: "12px",
        border: "1px solid #f00",
        display: recording ? "block" : "none",
      }}
    >
      {formatTime(recordingTime)}
    </div></>
    }
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Tabs defaultActiveKey="2"  items={tabItems} />
    </div>
  );
}

export default App;
